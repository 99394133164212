
@font-face {
  font-family: 'Ubuntu';
  font-weight: bold;
  font-style: normal;
  src: url('/src/assets/fonts/Ubuntu-Bold.ttf') format('truetype');
}

/* Define the Ubuntu Bold Italic font */
@font-face {
  font-family: 'Ubuntu';
  font-weight: bold;
  font-style: italic;
  src: url('/src/assets/fonts/Ubuntu-BoldItalic.ttf') format('truetype');
}

/* Define the Ubuntu Italic font */
@font-face {
  font-family: 'Ubuntu';
  font-weight: normal;
  font-style: italic;
  src: url('/src/assets/fonts/Ubuntu-Italic.ttf') format('truetype');
}

/* Define the Ubuntu Light font */
@font-face {
  font-family: 'Ubuntu';
  font-weight: 300;
  font-style: normal;
  src: url('/src/assets/fonts/Ubuntu-Light.ttf') format('truetype');
}

/* Define the Ubuntu Medium font */
@font-face {
  font-family: 'Ubuntu';
  font-weight: 500;
  font-style: normal;
  src: url('/src/assets/fonts/Ubuntu-Medium.ttf') format('truetype');
}

/* Define the Ubuntu Regular font */
@font-face {
  font-family: 'Ubuntu';
  font-weight: normal;
  font-style: normal;
  src: url('/src/assets/fonts/Ubuntu-Regular.ttf') format('truetype');
}




body {
  margin: 0;
  font-family: 
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


* {
  font-family: "Ubuntu", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/violet.css';
@import '@radix-ui/colors/mauve.css';

/* reset */
input,
textarea,
button {
  all: unset;
  box-sizing: border-box;
}

.FormRoot {
  width: 260px;
}

.FormField {
  display: grid;
  margin-bottom: 10px;
}

.FormLabel {
  font-size: 15px;
  font-weight: 500;
  line-height: 35px;
  color: white;
}

.FormMessage {
  font-size: 13px;
  color: white;
  opacity: 0.8;
}

.Input,
.Textarea {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  font-size: 15px;
  color: white;
  background-color: var(--black-a5);
  box-shadow: 0 0 0 1px var(--black-a9);
}
.Input:hover,
.Textarea:hover {
  box-shadow: 0 0 0 1px black;
}
.Input:focus,
.Textarea:focus {
  box-shadow: 0 0 0 2px black;
}
.Input::selection,
.Textarea::selection {
  background-color: var(--black-a9);
  color: white;
}

.Input {
  padding: 0 10px;
  height: 35px;
  line-height: 1;
}

.Textarea {
  resize: none;
  padding: 10px;
}

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
  width: 100%;

  background-color: white;
  color: var(--violet-11);
  box-shadow: 0 2px 10px var(--black-a7);
}
.Button:hover {
  background-color: var(--mauve-3);
}
.Button:focus {
  box-shadow: 0 0 0 2px black;
}

.DialogOverlay {
  background-color: var(--black-a9);
  position: fixed;
  inset: 0;
  z-index: 1;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.DialogContent {
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 2;
}
.DialogContent:focus {
  outline: none;
}

.DialogTitle {
  margin: 0;
  font-weight: 500;
  color: var(--mauve-12);
  font-size: 17px;
}

.DialogDescription {
  margin: 10px 0 20px;
  color: var(--mauve-11);
  font-size: 15px;
  line-height: 1.5;
}




.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet-11);
  position: absolute;
  top: 10px;
  right: 10px;
}
.IconButton:hover {
  background-color: var(--violet-4);
}
.IconButton:focus {
  box-shadow: 0 0 0 2px var(--violet-7);
}


.Label {
  font-size: 15px;
  color: var(--violet-11);
  width: 90px;
  text-align: right;
}

.Input {
  width: 100%;
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 15px;
  line-height: 1;
  color: var(--violet-11);
  box-shadow: 0 0 0 1px var(--violet-7);
  height: 35px;
}
.Input:focus {
  box-shadow: 0 0 0 2px var(--violet-8);
}

.rt-TextFieldInput:where(.rt-variant-surface) + :where(.rt-TextFieldChrome) {
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
  outline: none !important;
}

.inactive {
  opacity: .2 !important;
}

.active {
  position: relative;
}
.active::before {
  position: absolute;
  top: 0;
  left: -20px;
  content: "→"
}

#prompt-area::placeholder {
  color: black;
  opacity: .2;
}

.also {
  background-color: red !important;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.ToggleGroup {
  display: flex;
justify-content: space-between;
  background-color: var(--mauve-6);
  border-radius: 4px;
  box-shadow: 0 2px 10px var(--black-a7);
  margin-bottom: 10px;
}

.ToggleGroupItem {
  background-color: white;
  color: var(--mauve-11);
  height: 35px;
  display: flex;
  flex: 1;
  /* width: 35px; */
  display: flex;
  font-size: 15px;
  line-height: 1;
  align-items: center;
  justify-content: center;
  margin-left: 1px;
}
.ToggleGroupItem:first-child {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ToggleGroupItem:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ToggleGroupItem:hover {
  background-color: var(--violet-3);
}
.ToggleGroupItem[data-state='on'] {
  background-color: var(--violet-5);
  color: var(--violet-11);
}
.ToggleGroupItem:focus {
  position: relative;
  box-shadow: 0 0 0 2px black;
}

.SwitchRoot {
  width: 42px;
  height: 25px;
  background-color: rgba(223, 226, 230, 1);
  border-radius: 9999px;
  position: relative;
}
.SwitchRoot:focus {
  /* box-shadow: 0 0 0 2px rgba(0, 101, 255, 1);; */
}
.SwitchRoot[data-state='checked'] {
  background-color: rgba(0, 101, 255, 1);;
}

.SwitchThumb {
  display: block;
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 9999px;
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;
}
.SwitchThumb[data-state='checked'] {
  transform: translateX(19px);
}


.input-focus:focus {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px rgba(0, 101, 255, 0.25);
}

.input-focus:disabled {
  background: rgba(223, 226, 230, 1) !important;
  color: rgba(93, 107, 130, 1) !important;
}

.input-focus.error {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px rgba(235, 87, 87, 0.25);
  border:1px solid rgba(235, 87, 87, 1) !important;
}

.disable {
  background-color: rgba(235, 237, 240, 1) !important;
  color: rgba(179, 185, 196, 1) !important;
  cursor: not-allowed !important;
}

.calendar {
  backdrop-filter: blur(12px);
  background: rgba(244, 244, 247, .8) !important;
  border-radius: 15px;
  overflow: hidden;
  line-height: 1.5;
}

.toastui-calendar-layout {
  background: rgba(244, 244, 247, .8) !important;
  
}

.toastui-calendar-day-names {
  border: 0!important;
  overflow: hidden!important;
}

.toastui-calendar-day-view .toastui-calendar-panel:not(.toastui-calendar-time), .toastui-calendar-week-view .toastui-calendar-panel:not(.toastui-calendar-time) {
  overflow-y: hidden!important;
}

/* div + .prompt-area */

#prompt-area > div > div > div {
  overflow: visible !important;
}

#prompt-area *::placeholder {
  color: #C2C7D0 !important;
}

#prompt-area {
  position: sticky;
  bottom: 0px;
  z-index: 2;
}


.spinner {
  font-size: 40px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
}
.spinner.center {
  /* position: absolute; */
  left: 20px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.spinner-blade {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0.094em;
  height: 0.2777em;
  border-radius: 0.5em;
  background-color: transparent;
  transform-origin: center -0.2222em;
  -webkit-animation: spinner-fade 1s infinite linear;
          animation: spinner-fade 1s infinite linear;
}
.spinner-blade:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  transform: rotate(0deg);
}
.spinner-blade:nth-child(2) {
  -webkit-animation-delay: 0.083s;
          animation-delay: 0.083s;
  transform: rotate(45deg);
}
.spinner-blade:nth-child(3) {
  -webkit-animation-delay: 0.166s;
          animation-delay: 0.166s;
  transform: rotate(90deg);
}
.spinner-blade:nth-child(4) {
  -webkit-animation-delay: 0.249s;
          animation-delay: 0.249s;
  transform: rotate(135deg);
}
.spinner-blade:nth-child(5) {
  -webkit-animation-delay: 0.332s;
          animation-delay: 0.332s;
  transform: rotate(180deg);
}
.spinner-blade:nth-child(6) {
  -webkit-animation-delay: 0.415s;
          animation-delay: 0.415s;
  transform: rotate(225deg);
}
.spinner-blade:nth-child(7) {
  -webkit-animation-delay: 0.498s;
          animation-delay: 0.498s;
  transform: rotate(270deg);
}
.spinner-blade:nth-child(8) {
  -webkit-animation-delay: 0.581s;
          animation-delay: 0.581s;
  transform: rotate(315deg);
}
.spinner-blade:nth-child(9) {
  -webkit-animation-delay: 0.664s;
          animation-delay: 0.664s;
  transform: rotate(360deg);
}
.spinner-blade:nth-child(10) {
  -webkit-animation-delay: 0.747s;
          animation-delay: 0.747s;
  transform: rotate(405deg);
}
.spinner-blade:nth-child(11) {
  -webkit-animation-delay: 0.83s;
          animation-delay: 0.83s;
  transform: rotate(450deg);
}
.spinner-blade:nth-child(12) {
  -webkit-animation-delay: 0.913s;
          animation-delay: 0.913s;
  transform: rotate(495deg);
}

@-webkit-keyframes spinner-fade {
  0% {
    background-color: #0065ff;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes spinner-fade {
  0% {
    background-color: #0065ff;
  }
  100% {
    background-color: transparent;
  }
}

.overlay {
  /* background: rgba(255, 255, 255, 0.9);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto; */
}


.AvatarRoot {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  user-select: none;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: var(--black-a3);
}

.AvatarImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.AvatarFallback {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: var(--violet-11);
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
}