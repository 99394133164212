/* .AvatarRoot {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    user-select: none;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background-color: var(--black-a3);
  }
  
  .AvatarImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }
  
  .AvatarFallback {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: var(--violet-11);
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
  } */

  #avatar-list ul li:last-child {
    display: flex;
    align-items: center;
    text-align: center;
  }

  #avatar-list ul li:last-child button {
    background-color: #c2c7d0;
    color: white;
  }

  .events-container .event {
    cursor: pointer;
    transition: all .1s ease-in-out;
  }

  .events-container .event:hover {
    /* background-color: #f5f5f5 !important;
     */
     box-shadow: rgba(1,101,255,.2) 0px 4px 12px 0px, rgba(1,101,255,.2) 0px 0px 0px 1px  !important;
    transform: translateY(-10px);
  }